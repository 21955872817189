import React, {
  useState,
  Fragment,
  useEffect,
  useContext,
  useRef,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate, Link } from "react-router-dom";
import ReactStars from "react-stars";
import { toTitleCase, registerGtagEvent } from "./../utils/helpers";
import { Tab } from "@headlessui/react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getReviewsById, clearReviews } from "./../store/actions/ReviewActions";
import { createBid } from "./../store/actions/BidActions";
import { showUser } from "./../store/actions/UserActions";
import { getProductById } from "./../store/actions/ProductActions";
import Rating from "./../modals/Rating";
import { toast } from "react-toastify";
import ContentLoader from "react-content-loader";
import RequestReRunMOQModal from "../modals/RequestReRunMOQModal";
import withRouter from "../utils/withRouter";
import { AuthModalContext } from "./../contexts/auth";
import {
  TwitterShareButton,
  XIcon,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { useSearchParams } from "react-router-dom";
import instance from "../utils/instance";
import {isAuthenticated} from '../utils/helpers'

const authenticated = isAuthenticated();

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BlockLoader = ({
  height = 300,
  width = "100%",
  radius = 8,
  uniqueKey = "unique",
}) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    speed={2}
    width={width}
    height={height}
    backgroundColor="#ECEBEB"
    foregroundColor="#b0b0b0"
  >
    <rect
      x="0"
      y="0"
      rx={`${radius}`}
      ry={`${radius}`}
      width="100%"
      height={`${height}`}
    />
  </ContentLoader>
);

const OrderModal = (props) => {
  const {
    handleInputChange,
    product,
    moq,
    setQuantity,
    quantity,
    shipping,
    setShipping,
    createBid,
    loading,
    authenticated,
    addBidToCart,
  } = props;

  return (
    <Transition appear show={props?.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props?.closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center bg-black/40">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <form
                className="w-full max-w-2xl"
                onSubmit={(e) => {
                  e.preventDefault();
                  createBid();
                }}
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium leading-6 text-gray-900 mb-5"
                  >
                    Make Order
                  </Dialog.Title>
                  <div className="mt-2 space-y-5">
                    <div className="flex flex-col space-y-2">
                      <label className="text-[#5B5B5B] font-semibold text-lg">
                        Total Quantity
                      </label>
                      <input
                        className="focus:outline-none py-2 border-2 border-[#5B5B5B] rounded px-2"
                        placeholder="Quantity"
                        type={"number"}
                        min={
                          moq?.below_moq_price && moq?.below_moq_price > 0
                            ? 1
                            : moq?.min_quantity
                        }
                        name="quantity"
                        onChange={(e) => setQuantity(e.target.value)}
                        value={quantity}
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="text-[#5B5B5B] font-semibold text-lg">
                        Order Attributes
                      </label>
                      {props?.bidAttributes.map((x, i) => (
                        <div
                          key={i}
                          className="grid grid-cols-1 md:grid-cols-4 gap-x-2"
                        >
                          <div className="flex flex-col space-y-2">
                            <label className="text-[#5B5B5B] font-semibold">
                              Size/Patterns
                            </label>
                            <select
                              name="size_id"
                              value={x.size_id}
                              className="focus:outline-none py-2 border-2 border-[#5B5B5B] rounded px-2"
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <option value={""}>Select Size/Pattern</option>
                              handleInputChange
                              {product?.readable_sizes &&
                                product?.readable_sizes.length > 0 &&
                                product?.readable_sizes.map((size, i) => (
                                  <option key={i} value={size.id}>
                                    {size?.label}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="flex flex-col space-y-2">
                            <label className="text-[#5B5B5B] font-semibold">
                              Color
                            </label>
                            <select
                              name={`color_id-${i}`}
                              value={x.color_id}
                              className="focus:outline-none py-2 border-2 border-[#5B5B5B] rounded px-2"
                              onChange={(e) => handleInputChange(e, i, true)}
                            >
                              <option value={""}>Select Color</option>
                              {product?.readable_colors &&
                                product?.readable_colors.length > 0 &&
                                product?.readable_colors.map((color, i) => (
                                  <option
                                    key={i}
                                    value={color.id}
                                    className="text-gray-50"
                                    style={{
                                      backgroundColor: `${color?.value}`,
                                    }}
                                  >
                                    {" "}
                                    <span className="h-3 w-3 bg-gray-900">
                                      {color?.label}
                                    </span>
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="flex flex-col space-y-2">
                            <label className="text-[#5B5B5B] font-semibold">
                              Quantity
                            </label>
                            <input
                              className="focus:outline-none py-2 border-2 border-[#5B5B5B] rounded px-2"
                              placeholder="Quantity"
                              type={"number"}
                              min={0}
                              name="quantity"
                              value={x.quantity}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                          <div className="flex flex-row items-end justify-end space-x-2">
                            {props?.bidAttributes.length - 1 === i && (
                              <button
                                className="p-[10px] bg-tangerine rounded text-white h-fit"
                                onClick={() => props?.handleAddClick()}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={3}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 4.5v15m7.5-7.5h-15"
                                  />
                                </svg>
                              </button>
                            )}
                            {props?.bidAttributes.length !== 1 && (
                              <button
                                className="p-[10px] bg-tangerine rounded text-white h-fit"
                                onClick={() => props?.handleRemoveClick(i)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="text-[#5B5B5B] font-semibold text-lg">
                        Shipping Method
                      </label>
                      <div className="gap-x-3 my-4 grid grid-cols-2 mb-4">
                        <div
                          className="flex flex-row rounded border-2 border-litudian-orange relative cursor-pointer"
                          onClick={() => setShipping("SEA")}
                        >
                          <div className="px-4 bg-green-500 text-white flex flex-col justify-center items-center">
                            <img
                              className="m-0 p-0"
                              src="/img/ship.png"
                              alt="ship"
                            />
                          </div>
                          <div className="flex flex-col flex-1 px-2 py-1">
                            <h4 className="text-gray-700 text-sm mb-0 font-bold py-4">
                              Sea
                            </h4>
                          </div>
                          {shipping === "SEA" && (
                            <div className="absolute -top-2 -right-2 bg-tangerine rounded-full text-white p-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                        <div
                          className="flex flex-row rounded border-2 border-litudian-orange relative cursor-pointer"
                          onClick={() => setShipping("AIR")}
                        >
                          <div className="px-4 bg-blue-500 text-white flex flex-col justify-center items-center">
                            <img
                              className="m-0 p-0"
                              src="/img/plane.png"
                              alt="plane"
                            />
                          </div>
                          <div className="flex flex-col flex-1 px-2 py-1">
                            <h4 className="text-gray-700 text-sm mb-0 font-bold py-4">
                              Air
                            </h4>
                          </div>
                          {shipping === "AIR" && (
                            <div className="absolute -top-2 -right-2 bg-tangerine rounded-full text-white p-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex flex-row items-center justify-end space-x-5">
                    {authenticated && (
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-tangerine px-4 py-2 text-sm font-medium text-white hover:bg-litudian-orange focus:outline-none focus-visible:ring-2 focus-visible:ring-litudian-orange focus-visible:ring-offset-2 make-order-1"
                      >
                        {!loading ? "Make Order" : "Please wait ..."}
                      </button>
                    )}
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-tangerine px-4 py-2 text-sm font-medium text-white hover:bg-litudian-orange focus:outline-none focus-visible:ring-2 focus-visible:ring-tangerine focus-visible:ring-offset-2"
                      onClick={() => addBidToCart(0)}
                    >
                      View Summary
                    </button>
                  </div>
                </Dialog.Panel>
              </form>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const Product = ({
  moq,
  getReviewsById,
  reviews: { data: reviews },
  product,
  showUser,
  user: { details },
  createBid: newBid,
  getProductById,
  products,
  clearReviews,
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [img, setImg] = useState(
    moq !== null && moq?.product ? moq?.product[0]?.images[0] : ""
  );
  const [bidAttributes, setBidAttributes] = useState([
    { size_id: 0, quantity: 0, color_id: 0 },
  ]);
  const [attributes, setAttributes] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [promo_code, setPromoCode] = useState('');
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
  const [shipping, setShipping] = useState("AIR");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [sizeId, setSizeId] = useState("");
  const [colorId, setColorId] = useState("");
  // const [selectedColor, setSelectedColor] = useState('#fff')
  const [editOrder, setEditOrder] = useState(false);
  const [orderIndex, setOrderIndex] = useState(0);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [section, setSection] = useState(0);
  const [showRequestModal, setShowRequestModal] = useState(false);

  const { openModal: openAuthModal } = useContext(AuthModalContext);
  const handleCopy = () => {toast.success("Link copied to clipboard!");};

  function closeModal() {
    setIsOpen(false);
  }

  // Hooks
  const [searchParams] = useSearchParams();
  const promoCodeRef = useRef(null);

  // Effects
  useEffect(() => {
    let queryString = searchParams.get("affiliate");
    if (queryString) {
      localStorage.setItem(`affiliate_code`, queryString);
    } else {
      localStorage.removeItem(`affiliate_code`);
    }
  }, []);

  React.useEffect(() => {
    if (!moq?.product) return;
    setImg(moq?.product[0]?.images[0]);
    setQuantity(moq?.min_quantity || 0);
    async function fetchReviewById() {
      if (moq?.product_id) {
        await getReviewsById(moq?.product_id);
      }
    }
    async function findUser() {
      let uid = localStorage.getItem("ltdn_uid");

      if (uid && authenticated) {
        await showUser(uid);
      }
    }
    fetchReviewById();
    findUser();
    getBidsFromCard();
    return () => {
      clearReviews();
    };
  }, [moq, getReviewsById, showUser]);

  useEffect(() => {
    if (product === null) return;

    if (moq && moq.product_id) {
      fetchProduct();
    }

    async function fetchProduct() {
      await getProductById(moq.product_id);
    }
  }, [moq, getProductById]);

  useEffect(() => {
    let cart_bids = getBidsFromCard();

    let findBid = cart_bids?.find(
      (b) => parseInt(b?.moq_id) === parseInt(moq?.id)
    );

    if (findBid) {
      setQuantity(findBid.quantity);
      setBidAttributes(findBid?.bid_attributes);
    }
  }, [moq]);

  useEffect(() => {
    let total_bid_attributes_quantity = bidAttributes.reduce(
      (accumulator, object) => {
        return accumulator + object?.quantity;
      },
      0
    );

    if (total_bid_attributes_quantity > moq?.min_quantity) {
      setQuantity(total_bid_attributes_quantity || 0);
    }
  }, [bidAttributes, moq]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window?.location?.search);
    if (searchParams.get("action") === "review") {
      setActiveTabIndex(2);
    }
  }, []);

  const toggleRatingModal = () => setShow(!show);

  const handleAddClick = () => {
    setBidAttributes([
      ...bidAttributes,
      { size_id: 0, quantity: 0, color_id: 0 },
    ]);
  };

  const handleRemoveClick = (index) => {
    let list = [...bidAttributes];

    list.splice(index, 1);

    setBidAttributes(list);
  };

  const handleInputChange = (e, index, color_id = false) => {
    const { name, value } = e.target;

    let attributes = [...bidAttributes];

    if (color_id === true) {
      attributes[index]["color_id"] = parseInt(value);
    } else {
      attributes[index][name] = parseInt(value);
    }

    if (
      product?.readable_sizes.length === 1 &&
      (product?.readable_sizes[0]?.label === "None" ||
        product?.readable_sizes[0]?.label === "none")
    ) {
      attributes[index]["size_id"] = product?.readable_sizes[0]?.id;
    }

    if (
      product?.readable_colors.length === 1 &&
      (product?.readable_colors[0]?.label === "None" ||
        product?.readable_colors[0]?.label === "none")
    ) {
      attributes[index]["color_id"] = product?.readable_colors[0]?.id;
    }

    setBidAttributes(attributes);
  };

  const createBid = async () => {
    try {
      if (!authenticated) {
        toast.error("Please login to make an order !", { autoClose: 10000 });
        return;
      }

      if (loading) return;

      let uid = localStorage.getItem("ltdn_uid");

      let userData = await showUser(uid);

      let data = [],
        qty = 0,
        values = {};

      const affiliate_code = localStorage.getItem("affiliate_code") || null;

      values.quantity = quantity;

      if (quantity === 0) {
        return toast.warning(
          `Please provided the required quantity which must be ${moq?.min_quantity} or above`,
          {
            position: toast.POSITION.TOP_RIGHT,

            autoClose: 10000,
          }
        );
      }

      let below_moq_price = getBelowMOQPrice(moq);

      if (!below_moq_price > 0 && quantity < moq?.min_quantity) {
        return toast.error(
          `Minimum quantity required for this order to be submitted is ${moq?.min_quantity} and not ${quantity}`
        );
      }

      for (let i = 0; i < bidAttributes.length; i++) {
        if (
          !(
            product?.readable_sizes &&
            product?.readable_sizes.length === 1 &&
            (product?.readable_sizes[0]?.label === "None" ||
              product?.readable_sizes[0]?.label === "none")
          ) &&
          (bidAttributes[i].size_id === 0 ||
            bidAttributes[i].size_id === "None")
        ) {
          return toast.warning(
            "Please select appropriate size in order attributes!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }

        if (
          !(
            product?.readable_colors &&
            product?.readable_colors.length === 1 &&
            (product?.readable_colors[0]?.label === "None" ||
              product?.readable_colors[0]?.label === "none")
          ) &&
          (bidAttributes[i].color_id === 0 ||
            bidAttributes[i].color_id === "None")
        ) {
          return toast.warning(
            "Please select appropriate color in order attributes!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }

        if (bidAttributes[i].quantity === 0) {
          return toast.warning(
            "Quantity on order attributes should be more than zero!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }
      }

      if (
        bidAttributes.length > 0 &&
        bidAttributes[0].quantity !== 0 &&
        (bidAttributes[0].color_id !== 0 || bidAttributes[0].size_id !== 0)
      ) {
        data = bidAttributes;

        bidAttributes.forEach((attribute) => {
          qty = qty + attribute.quantity;
        });

        if (parseInt(values.quantity) !== qty) {
          toast.warning(
            "Sum of quantity on order attrbutes must be same as MOQ quantity provided!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );

          return null;
        }
      }

      if (
        moq?.product[0]?.varying_sizes &&
        moq?.product[0]?.varying_sizes === 1
      ) {
        let moq_attributes = moq?.moq_attributes || [];
        for (let x = 0; x < data.length; x++) {
          let shipping_fee_air =
            moq_attributes?.find((a) => a?.size_id === data[x]?.size_id)
              ?.shipping_fee_air || "";
          let shipping_fee_sea =
            moq_attributes?.find((a) => a?.size_id === data[x]?.size_id)
              ?.shipping_fee_sea || "";
          data[x].shipping_fee_air = shipping_fee_air;
          data[x].shipping_fee_sea = shipping_fee_sea;
        }
      }

      values.msisdn = userData?.msisdn;

      values.status = 0;

      values.bid_attributes = data;

      values.moq_id = moq?.id;

      values.shipping = shipping;

      values.send_sms = 1;

      if (affiliate_code) {
        values.affiliate_code = affiliate_code;
      }

      // Include promo code if it's not null and it's valid
      if (isPromoCodeValid === true && promo_code !== '') {
        values.promo_code = promo_code;
      }

      setLoading(true);

      const result = await newBid(values);

      if (result) {
        toast.success("Order Placed Successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setBidAttributes([{ size_id: 0, quantity: 0, color_id: 0 }]);

        setQuantity(0);

        setLoading(false);

        registerGtagEvent("order_button_click");
        registerGtagEvent("conversion")

        return navigate(`/items/${result?.id}`);
      }
    } catch (error) {
      setLoading(false);

      if (
        error &&
        error?.response?.data &&
        error?.response?.data !== undefined &&
        error?.response?.data?.message !== undefined
      ) {
        return toast.error(`${error.response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      toast.error("Please try again later !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getBidsFromCard = () => {
    let data = JSON.parse(localStorage.getItem("bids")) || [];

    return data;
  };

  const addItemToCart = (bid) => {
    let data = getBidsFromCard();

    let itemIndex = data.findIndex((b) => b?.moq_id === bid?.moq_id);

    if (itemIndex < 0) {
      data.push(bid);

      localStorage.setItem("bids", JSON.stringify(data));

      return;
    }

    let item = data.find((b) => b?.moq_id === bid?.moq_id);

    data[itemIndex] = { ...bid, id: item?.id };

    localStorage.setItem("bids", JSON.stringify(data));

    const broadcastChannel = new BroadcastChannel("LitudianChannel");
    broadcastChannel.postMessage("refresh-cart");

    return;
  };

  const addBidToCart = async () => {
    try {
      let card_bids = getBidsFromCard();

      let data = [],
        qty = 0,
        values = {};

      values.moq = moq;

      values.product = product;

      values.quantity = quantity;

      const affiliate_code = localStorage.getItem("affiliate_code") || null;

      if (moq?.below_moq_price === 0 && quantity === 0) {
        return toast.warning(
          `Please provided the required quantity which must be ${moq?.min_quantity} or above`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }

      for (let i = 0; i < bidAttributes.length; i++) {
        if (
          bidAttributes[i].size_id === 0 ||
          bidAttributes[i].size_id === "None"
        ) {
          return toast.warning(
            "Please select appropriate size in order attributes!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }

        if (
          bidAttributes[i].color_id === 0 ||
          bidAttributes[i].color_id === "None"
        ) {
          return toast.warning(
            "Please select appropriate color in order attributes!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }

        if (bidAttributes[i].quantity === 0) {
          return toast.warning(
            "Quantity on order attributes should be more than zero!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }
      }

      if (
        bidAttributes.length > 0 &&
        bidAttributes[0].size_id !== 0 &&
        bidAttributes[0].quantity !== 0 &&
        bidAttributes[0].color_id !== 0
      ) {
        data = bidAttributes;

        bidAttributes.forEach((attribute) => {
          qty = qty + attribute.quantity;
        });

        if (parseInt(values.quantity) !== qty) {
          toast.warning(
            "Sum of quantity on order attributes must be same as MOQ quantity provided!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );

          return null;
        }
      }

      if (
        moq?.product[0]?.varying_sizes &&
        moq?.product[0]?.varying_sizes === 1
      ) {
        let moq_attributes = moq?.moq_attributes || [];
        for (let x = 0; x < data.length; x++) {
          let shipping_fee_air =
            moq_attributes?.find((a) => a?.size_id === data[x]?.size_id)
              ?.shipping_fee_air || "";
          let shipping_fee_sea =
            moq_attributes?.find((a) => a?.size_id === data[x]?.size_id)
              ?.shipping_fee_sea || "";
          data[x].shipping_fee_air = shipping_fee_air;
          data[x].shipping_fee_sea = shipping_fee_sea;
        }
      }

      // values.msisdn = userData?.msisdn;

      values.status = 0;

      values.bid_attributes = data;

      values.moq_id = moq?.id;

      values.shipping = shipping;

      values.id = card_bids.length + 1;

      if (affiliate_code) {
        values.affiliate_code = affiliate_code;
      }

      setLoading(true);

      // console.log(values)

      // addItemToCart(values)

      toast.success("Order Added to Cart Successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });

      // setBidAttributes([{ size_id: 0, quantity: 0, color_id: 0 }]);

      // setQuantity(0);

      setLoading(false);

      const broadcastChannel = new BroadcastChannel("LitudianChannel");
      broadcastChannel.postMessage("refresh-cart");

      // closeModal()

      // return navigate('/cart')
    } catch (error) {
      setLoading(false);
    }
  };

  const addOrderSummaryToCart = async () => {
    try {
      let card_bids = getBidsFromCard();

      let data = [],
        qty = 0,
        values = {};

      if (quantity === 0) {
        return toast.warning(
          `Please provided the required quantity which must be ${moq?.min_quantity} or above`,
          {
            position: toast.POSITION.TOP_RIGHT,

            autoClose: 10000,
          }
        );
      }

      values.moq = moq;

      values.product = product;

      const affiliate_code = localStorage.getItem("affiliate_code") || null;

      for (let i = 0; i < bidAttributes.length; i++) {
        if (
          bidAttributes[i].size_id === 0 ||
          bidAttributes[i].size_id === "None"
        ) {
          return toast.warning(
            "Please select appropriate size/pattern in order attributes!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }

        if (
          bidAttributes[i].color_id === 0 ||
          bidAttributes[i].color_id === "None"
        ) {
          return toast.warning(
            "Please select appropriate color in order attributes!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }

        if (bidAttributes[i].quantity === 0) {
          return toast.warning(
            "Quantity on order attributes should be more than zero!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }
      }

      if (
        bidAttributes.length > 0 &&
        bidAttributes[0].quantity !== 0 &&
        (bidAttributes[0].color_id !== 0 || bidAttributes[0].size_id !== 0)
      ) {
        data = bidAttributes;

        bidAttributes.forEach((attribute) => {
          qty = qty + attribute.quantity;
        });

        // if (parseInt(moq?.min_quantity) > qty) {
        //   toast.warning(
        //     `Sum of quantity on order attrbutes must be same  or more than ${parseInt(
        //       moq?.min_quantity
        //     )}!`,
        //     {
        //       position: toast.POSITION.TOP_RIGHT,

        //       autoClose: 10000,
        //     }
        //   );

        //   return null;
        // }
      }

      // values.msisdn = userData?.msisdn;

      values.status = 0;

      values.bid_attributes = data;

      values.moq_id = moq?.id;

      values.shipping = shipping;

      values.id = card_bids.length + 1;

      values.quantity = qty;

      // Include promo code if it's not null and it's valid
      if (isPromoCodeValid === true && promo_code !== '') {
        values.promo_code = promo_code;
      }

      if (affiliate_code) {
        values.affiliate_code = affiliate_code;
      }

      setLoading(true);

      addItemToCart(values);

      setBidAttributes([{ size_id: 0, quantity: 0, color_id: 0 }]);

      setQuantity(0);

      setAttributes([]);

      setLoading(false);

      closeModal();

      const broadcastChannel = new BroadcastChannel("LitudianChannel");
      broadcastChannel.postMessage("refresh-cart");

      if (authenticated) {
        toast.success(
          "Order Added to Cart Successfully !",
          {
            position: toast.POSITION.TOP_RIGHT,
          },
          { autoClose: 15000 }
        );
        return (window.location.href = "/cart");
      } else {
        toast.success(
          "Order Added to Cart Successfully. Log in to place order !",
          {
            position: toast.POSITION.TOP_RIGHT,
          },
          { autoClose: 15000 }
        );
      }

      //return navigate("/cart");
      return "";
    } catch (error) {
      setLoading(false);
    }
  };

  function findMinMax(arr) {
    let min = arr ? arr[0].price : 0,
      max = arr ? arr[0].price : 0,
      length = arr ? arr.length : 0;

    for (let i = 1, len = length; i < len; i++) {
      let v = arr[i].price;
      min = v < min ? v : min;
      max = v > max ? v : max;
    }

    return { min, max };
  }

  const getTotal = () => {
    let sum = 0;

    if (
      moq?.product &&
      moq?.product[0] &&
      moq?.product[0]?.varying_sizes === 1
    ) {
      bidAttributes.map((attr) => {
        let price = 0;
        if (
          moq?.moq_attributes?.find((a) => a?.size_id === attr?.size_id)
            ?.below_moq_price > 0 &&
          moq?.min_quantity > quantity
        ) {
          price =
            moq?.moq_attributes?.find((a) => a?.size_id === attr?.size_id)
              ?.below_moq_price || 0;
        } else {
          price =
            moq?.moq_attributes?.find((a) => a?.size_id === attr?.size_id)
              ?.price || 0;
        }

        let total = price * attr?.quantity;

        sum += total;
      });
    }

    return sum;
  };

  function findBelowMOQPriceMinMax(arr) {
    let min = 0;

    let max = 0;

    if (arr === undefined) {
      return { min, max };
    } else {
      min = arr && arr?.length > 0 ? arr[0].below_moq_price || 0 : 0;

      max = arr && arr?.length > 0 ? arr[0].below_moq_price || 0 : 0;
    }

    if (arr && arr?.length > 0) {
      for (let i = 1, len = arr.length; i < len; i++) {
        let v = arr[i].below_moq_price || 0;
        min = v < min ? v : min;
        max = v > max ? v : max;
      }
    }

    return [min, max];
  }

  function getBelowMOQPrice(value) {
    let varying_sizes = value?.product[0]?.varying_sizes || 0;
    if (varying_sizes === 1) {
      let data = findBelowMOQPriceMinMax(value?.moq_attributes);
      if (data?.length === 2 && data[0] === data[1]) {
        return data[0]?.toLocaleString();
      } else {
        return `${data[0]?.toLocaleString()} - ${data[1]?.toLocaleString()}`;
      }
    } else {
      return value?.below_moq_price?.toLocaleString() || 0;
    }
  }

  let total_bid = moq?.bids || [];

  let total_items = total_bid.reduce((accumulator, object) => {
    return accumulator + object?.quantity;
  }, 0);

  if (moq !== null && moq.length === 0 && !product) {
    return (
      <div className="flex flex-col justify-center items-center w-full min-h-[200px]">
        <h1 className="text-[#A68F69] dark:text-tangerine">
          No Product Available
        </h1>
      </div>
    );
  }

  if (!product) {
    return <BlockLoader height={"80vh"} radius={4} />;
  }

  const getPercentage = (bids, quantity) => {
    if (bids !== undefined && quantity !== undefined) {
      let order_quantity =
        bids?.length > 0
          ? bids
              .map((item) => item?.quantity)
              .reduce((prev, next) => prev + next)
          : 0;
      return Math.trunc((order_quantity * 100) / quantity);
    }
    return 0;
  };

  let productLink = `${window.location.origin}${window.location.pathname}`;
  if (details && details?.affiliate_code) {
    productLink = `${window.location.origin}${window.location.pathname}?affiliate=${details?.affiliate_code}`;
  }

  let below_moq_price = getBelowMOQPrice(moq);

  return (
    <>
      <div className="w-full flex-col py-3">
        <div className="text-tangerine flex flex-row items-center justify-start space-x-1 font-extrabold">
          <Link to="/" className="font-black">
            Home
          </Link>

          <span className="mx-1 text-2xl font-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>

          {products?.product?.category_name && (
            <Link
              className="font-black capitalize"
              to={`/categories/${products?.product?.category_id}`}
            >
              {toTitleCase(products?.product?.category_name || "")}
            </Link>
          )}

          {products?.product?.category_name && (
            <span className="mx-1 text-2xl font-black">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </span>
          )}

          <span className="text-litudian-orange font-black">
            {product !== null &&
              moq !== null &&
              moq?.product &&
              toTitleCase(moq?.product[0]?.name)}
          </span>
        </div>

        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-8 mt-3">
          <div className="">
            <h1 className="text-2xl text-gray-700 dark:text-white font-bold">
              {product !== null &&
                moq !== null &&
                moq?.product &&
                toTitleCase(moq?.product[0]?.name)}
            </h1>
            <p className="text-gray-700 dark:text-white mt-5">Share to:</p>
            <div className="flex flex-row items-center space-x-2">
              <FacebookShareButton
                url={productLink}
                quote={toTitleCase(moq?.product[0]?.name)}
                hashtag="litudian"
              >
                <FacebookIcon round size={38} />
              </FacebookShareButton>
              <TwitterShareButton
                url={productLink}
                title={toTitleCase(moq?.product[0]?.name)}
                via="litudian"
              >
                <XIcon round size={38} />
              </TwitterShareButton>
              <EmailShareButton
                url={productLink}
                subject={toTitleCase(moq?.product[0]?.name)}
              >
                <EmailIcon round size={38} />
              </EmailShareButton>
              <TelegramShareButton
                url={productLink}
                title={toTitleCase(moq?.product[0]?.name)}
              >
                <TelegramIcon round size={38} />
              </TelegramShareButton>
              <WhatsappShareButton
                url={productLink}
                title={toTitleCase(moq?.product[0]?.name)}
              >
                <WhatsappIcon round size={38} />
              </WhatsappShareButton>
              <CopyToClipboard text={productLink} onCopy={handleCopy}>
                <svg xmlns="http://www.w3.org/2000/svg"
                  height="30"
                  viewBox="0 0 24 24"
                  width="30"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{
                    cursor: "pointer",
                    borderRadius: '50%',
                  }}>
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                </svg>
              </CopyToClipboard>
            </div>
          </div>

          <div className="md:col-span-2 flex flex-col">
            <div className="flex flex-col md:flex-row md:justify-between md:items-center w-full space-y-3 md:space-y-0">
              <div className="flex flex-col">
                {product !== null &&
                moq?.moq_attributes &&
                moq?.product &&
                moq?.product[0] &&
                moq?.product[0]?.varying_sizes === 1 ? (
                  <h2 className="text-2xl font-bold text-tangerine">
                    KES{" "}
                    {findMinMax(moq?.moq_attributes)?.min
                      ? findMinMax(moq?.moq_attributes)?.min.toLocaleString()
                      : 0}{" "}
                    -{" "}
                    {findMinMax(moq?.moq_attributes)?.max
                      ? findMinMax(moq?.moq_attributes)?.max.toLocaleString()
                      : 0}
                  </h2>
                ) : (
                  <h2 className="text-2xl font-bold text-tangerine">
                    KES {moq?.price ? moq?.price.toLocaleString() : 0}
                  </h2>
                )}

                {below_moq_price !== 0 && below_moq_price !== "0" && (
                  <p className="text-md text-gray-600 dark:text-white font-bold text-sm">
                    Below MOQ price: KES {getBelowMOQPrice(moq)}
                  </p>
                )}

                <p className="text-md text-gray-600 dark:text-white font-bold text-sm">
                  MOQ Per Person: {moq?.min_quantity} items / bundle
                </p>

                <p className="text-md text-gray-600 dark:text-white font-bold text-sm">
                  MOQ Number: {moq?.moq_number}
                </p>

                <p className="text-md text-gray-600 dark:text-white font-bold text-sm">
                  MOQ Status:{" "}
                  {moq?.status === 0 ||
                  moq?.moq_order_status !== "not placed" ? (
                    <span className="text-red-500">Not Active</span>
                  ) : (
                    "Active"
                  )}
                </p>
              </div>

              <div className="flex flex-col">
                <p className="text-md text-gray-900 dark:text-white font-bold text-sm">
                  Supplier MOQ: {moq?.quantity} items
                </p>

                <p className="text-md text-gray-600 dark:text-gray-300 font-bold text-sm">
                  Group buy orders so far: {total_items} <br />
                  Pending to Supplier MOQ
                </p>

                <p className="text-md text-tangerine font-bold text-sm">{`${getPercentage(
                  moq?.bids,
                  moq?.quantity
                )}% Sold`}</p>
              </div>

              <div className="flex flex-col">
                <h2 className="text-lg font-bold text-gray-600 dark:text-white">
                  Rating
                </h2>

                <div className="flex flex-row justify-start md:justify-center items-center gap-x-1">
                  <ReactStars
                    value={
                      reviews && reviews.length > 0
                        ? reviews
                            .map((item) => item.rating)
                            .reduce((prev, next) => prev + next) /
                          reviews.length
                        : 0
                    }
                    count={2}
                    size={26}
                    activeColor="#ffd700"
                    edit={false}
                  />

                  <p className="c font-extrabold">
                    {reviews && reviews.length > 0
                      ? (
                          reviews
                            .map((item) => item.rating)
                            .reduce((prev, next) => prev + next) /
                          reviews.length
                        ).toFixed(2)
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="h-full w-full flex flex-col mt-16">
            <div className="w-full">
              <img
                className="h-100 object-cover w-full"
                src={img || "https://via.placeholder.com/300"}
                alt={toTitleCase(moq?.product[0]?.name)}
                onError={(e) => {
                  e.target.src = "https://via.placeholder.com/300";
                }}
              />
            </div>

            {moq !== null &&
              moq?.product &&
              moq?.product[0]?.images !== undefined &&
              moq?.product[0]?.images.length > 1 && (
                <div className="grid grid-cols-4 gap-2 mt-3">
                  {moq?.product &&
                    moq?.product[0]?.images.slice(0, 4).map((image, i) => (
                      <div
                        key={i}
                        className="bg-white w-full rounded-md shadow-sm cursor-pointer"
                        onClick={() =>
                          setImg(image || "https://via.placeholder.com/300")
                        }
                      >
                        <img
                          className="h-full object-contain w-full"
                          src={image || "https://via.placeholder.com/300"}
                          alt={"product"}
                          onError={(e) => {
                            e.target.src = "https://via.placeholder.com/300";
                          }}
                        />
                      </div>
                    ))}
                </div>
              )}
          </div>

          <div className="md:col-span-2 flex flex-col bg-[#fff] dark:bg-[#252525] border-[#5B5B5B] rounded mb-10 md:px-4">
            <Tab.Group defaultIndex={activeTabIndex}>
              <Tab.List className="flex flex-row justify-between">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      `w-full rounded-lg py-2.5 font-bold text-lg leading-5 focus:outline-none ${
                        selected ? "text-litudian-orange" : "text-tangerine"
                      } text-left px-2`
                    )
                  }
                >
                  Order
                </Tab>

                <Tab
                  className={({ selected }) =>
                    classNames(
                      `w-full rounded-lg py-2.5 font-bold text-lg leading-5 focus:outline-none ${
                        selected ? "text-litudian-orange" : "text-tangerine"
                      } text-left px-2`
                    )
                  }
                >
                  Description
                </Tab>

                <Tab
                  className={({ selected }) =>
                    classNames(
                      `w-full rounded-lg py-2.5 font-bold text-lg leading-5 focus:outline-none ${
                        selected ? "text-litudian-orange" : "text-tangerine"
                      } text-left px-2`
                    )
                  }
                >
                  Customer Reviews
                </Tab>
              </Tab.List>

              <Tab.Panels className="w-full">
                <Tab.Panel>
                  <div className="w-full grid grid-cols-1 bg-[#F9F9F9] gap-y-1 px-4 md:px-8 py-4 md:py-2 rounded shadow-md my-5">
                    {section === 0 && (
                      <div className="col-span-full flex flex-col space-y-3">
                        <form
                          className="flex flex-col space-y-3 make-order-1"
                          onSubmit={(e) => {
                            e.preventDefault();

                            createBid();
                          }}
                        >
                          <h2 className="text-[#A68F69] font-bold text-lg">
                            Total Quantity
                          </h2>

                          <input
                            className="focus:outline-none border border-[#A68F69] box-border px-2 py-3 text-base font-bold rounded focus:ring-2 ring-[#A68F69] w-full disabled:bg-gray-200"
                            type={"number"}
                            min={
                              below_moq_price !== null &&
                              below_moq_price !== 0 &&
                              below_moq_price !== "0"
                                ? 1
                                : moq?.min_quantity
                            }
                            name="quantity"
                            onChange={(e) => setQuantity(e.target.value)}
                            value={quantity}
                            disabled={
                              moq?.status === 0 ||
                              moq?.moq_order_status !== "not placed"
                            }
                          />

                          <h2 className="text-[#A68F69] font-bold text-lg">
                            Order Attributes
                          </h2>

                          {bidAttributes.map((x, i) => (
                            <div
                              key={i}
                              className="w-full flex flex-col md:flex-row md:justify-between md:items-center mb-5 space-y-3 md:space-y-0"
                            >
                              <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-y-0 md:gap-x-3">
                                <div
                                  className={`${
                                    product?.readable_sizes &&
                                    product?.readable_sizes.length === 1 &&
                                    (product?.readable_sizes[0]?.label ===
                                      "None" ||
                                      product?.readable_sizes[0]?.label ===
                                        "none")
                                      ? "hidden"
                                      : "block"
                                  } space-y-2 w-full`}
                                >
                                  <h4 className="text-gray-600 font-semibold">
                                    Size/Patterns
                                  </h4>

                                  <div className="">
                                    <select
                                      name="size_id"
                                      value={x.size_id}
                                      className="focus:outline-none border border-[#A68F69] box-border px-2 py-3 text-base font-bold rounded focus:ring-2 ring-[#A68F69] w-full disabled:bg-slate-200"
                                      onChange={(e) => handleInputChange(e, i)}
                                      disabled={
                                        moq?.status === 0 ||
                                        moq?.moq_order_status !== "not placed"
                                      }
                                    >
                                      <option value={""}>
                                        Select Size/Pattern
                                      </option>

                                      {product?.readable_sizes &&
                                        product?.readable_sizes.length > 0 &&
                                        product?.readable_sizes.map(
                                          (size, i) => (
                                            <option
                                              key={i}
                                              value={size.id}
                                              // selected={
                                              //   (size?.label === "None" ||
                                              //     size?.label === "none") &&
                                              //   product?.readable_sizes
                                              //     .length === 1
                                              // }
                                            >
                                              {size?.label}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  </div>
                                </div>

                                <div
                                  className={`${
                                    product?.readable_colors &&
                                    product?.readable_colors.length === 1 &&
                                    (product?.readable_colors[0]?.label ===
                                      "None" ||
                                      product?.readable_colors[0]?.label ===
                                        "none")
                                      ? "hidden"
                                      : "block"
                                  } md:space-y-2 flex-1 min-w-[200px]`}
                                >
                                  <h4 className="text-gray-600 font-semibold">
                                    Color
                                  </h4>

                                  <div className="flex flex-row items-center space-x-1 w-full flex-1">
                                    <select
                                      name="color_id"
                                      value={x.color_id}
                                      className="focus:outline-none border border-[#A68F69] box-border px-2 py-3 text-base font-bold rounded focus:ring-2 ring-[#A68F69] w-full disabled:bg-slate-200"
                                      onChange={(e) => {
                                        handleInputChange(e, i);
                                      }}
                                      disabled={
                                        moq?.status === 0 ||
                                        moq?.moq_order_status !== "not placed"
                                      }
                                    >
                                      <option value={""}>Select Color</option>

                                      {product?.readable_colors &&
                                        product?.readable_colors.length > 0 &&
                                        product?.readable_colors.map(
                                          (color, i) => (
                                            <option key={i} value={color.id}>
                                              {color?.label}
                                            </option>
                                          )
                                        )}
                                    </select>

                                    <div
                                      className="h-[50px] w-[70px] rounded shadow-sm border border-[#A68F69]"
                                      style={{
                                        backgroundColor: `${
                                          product?.readable_colors &&
                                          product?.readable_colors.find(
                                            (color) =>
                                              color?.id === parseInt(x.color_id)
                                          )?.value
                                        }`,
                                      }}
                                    ></div>
                                  </div>
                                </div>

                                <div className="md:space-y-2">
                                  <h4 className="text-gray-600 font-semibold">
                                    Quantity
                                  </h4>

                                  <input
                                    className="focus:outline-none border border-[#A68F69] box-border px-2 py-3 text-base font-bold rounded focus:ring-2 ring-[#A68F69] w-full disabled:bg-slate-200"
                                    type={"number"}
                                    min={0}
                                    name="quantity"
                                    value={x.quantity}
                                    onChange={(e) => handleInputChange(e, i)}
                                    disabled={
                                      moq?.status === 0 ||
                                      moq?.moq_order_status !== "not placed"
                                    }
                                  />
                                </div>
                              </div>

                              <div className="flex flex-row justify-between items-center md:items-end space-x-1 px-1 md:px-0 md:space-x-2 h-full">
                                {bidAttributes.length - 1 === i &&
                                  !(
                                    product?.readable_colors &&
                                    product?.readable_colors.length === 1 &&
                                    (product?.readable_colors[0]?.label ===
                                      "None" ||
                                      product?.readable_colors[0]?.label ===
                                        "none") &&
                                    product?.readable_sizes &&
                                    product?.readable_sizes.length === 1 &&
                                    (product?.readable_sizes[0]?.label ===
                                      "None" ||
                                      product?.readable_sizes[0]?.label ===
                                        "none")
                                  ) && (
                                    <button
                                      className="bg-tangerine text-white p-2 rounded shadow-sm text-sm disabled:bg-tangerine/40"
                                      disabled={
                                        moq?.status === 0 ||
                                        moq?.moq_order_status !== "not placed"
                                      }
                                      onClick={() => {
                                        if (
                                          moq?.status === 0 ||
                                          moq?.moq_order_status !== "not placed"
                                        )
                                          return;

                                        handleAddClick();
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M12 4v16m8-8H4"
                                        />
                                      </svg>
                                    </button>
                                  )}

                                {bidAttributes.length !== 1 && (
                                  <button
                                    className="bg-tangerine text-white p-2 rounded shadow-sm text-sm"
                                    onClick={() => {
                                      if (
                                        moq?.status === 0 ||
                                        moq?.moq_order_status !== "not placed"
                                      )
                                        return;

                                      handleRemoveClick(i);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-4 w-4"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                      />
                                    </svg>
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}

                          <h1 className="text-[#A68F69] font-bold text-lg">
                            Shipping Method
                          </h1>

                          <div className="gap-y-3  md:gap-y-0 md:gap-x-3 my-4 grid grid-cols-1 md:grid-cols-2 mb-4">
                            <div
                              className="flex flex-row relative cursor-pointer bg-white rounded-xl"
                              onClick={() => {
                                if (
                                  moq?.status === 0 ||
                                  moq?.moq_order_status !== "not placed"
                                )
                                  return;

                                setShipping("SEA");
                              }}
                            >
                              <div className="px-4 bg-green-500 text-white flex flex-col justify-center items-center rounded-l-xl">
                                <img
                                  className="m-0 p-0"
                                  src="/img/ship.png"
                                  alt="ship"
                                />
                              </div>

                              <div className="flex flex-col flex-1 px-2 py-3 border-[#A68F69] border-y-2 border-r-2 rounded-r-xl">
                                <h4 className="text-[#5B5B5B] text-sm mb-0 font-bold">
                                  Sea approximate cost
                                </h4>

                                <p className="text-xs text-[#5B5B5B]">
                                  {product !== null &&
                                  moq?.moq_attributes[0]?.shipping_fee_sea
                                    ? `KES ${moq?.moq_attributes[0]?.shipping_fee_sea} each`
                                    : "-"}
                                </p>
                              </div>

                              {shipping === "SEA" && (
                                <div className="absolute -top-2 -right-2 bg-[#A68F69] rounded-full text-white p-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M5 13l4 4L19 7"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>

                            <div
                              className="flex flex-row relative cursor-pointer bg-white rounded-xl"
                              onClick={() => {
                                if (
                                  moq?.status === 0 ||
                                  moq?.moq_order_status !== "not placed"
                                )
                                  return;

                                setShipping("AIR");
                              }}
                            >
                              <div className="px-4 bg-blue-500 text-white flex flex-col justify-center items-center rounded-l-xl">
                                <img
                                  className="m-0 p-0"
                                  src="/img/plane.png"
                                  alt="plane"
                                />
                              </div>

                              <div className="flex flex-col flex-1 px-2 py-3 border-[#A68F69] border-y-2 border-r-2 rounded-r-xl">
                                <h4 className="text-[#5B5B5B] text-sm mb-0 font-bold">
                                  Air approximate cost
                                </h4>

                                <p className="text-xs text-[#5B5B5B]">
                                  {product !== null &&
                                  moq?.moq_attributes[0]?.shipping_fee_air
                                    ? `KES ${moq?.moq_attributes[0]?.shipping_fee_air} each`
                                    : "-"}
                                </p>
                              </div>

                              {shipping === "AIR" && (
                                <div className="absolute -top-2 -right-2 bg-[#A68F69] rounded-full text-white p-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M5 13l4 4L19 7"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                          </div>

                          <h2 className="text-[#A68F69] font-bold text-lg">
                            Promo Code
                          </h2>

                          <input
                            className="focus:outline-none border border-[#A68F69] box-border px-2 py-3 text-base font-bold rounded focus:ring-2 ring-[#A68F69] w-full disabled:bg-gray-200"
                            type={"text"}
                            name="promo_code"
                            onChange={async (e) => {
                              try {
                                setPromoCode(e.target.value);
                                let response = await instance.get(
                                  `validate_promo_code/${e.target.value}/${moq?.moq_number}`
                                );
                                if (response?.data?.status === "success") {
                                  setIsPromoCodeValid(true);
                                  let message = "";
                                  if (promoCodeRef?.current)
                                    message =
                                      `${response?.data?.message}` ||
                                      "Promo code is valid";
                                  if (response?.data?.discount) message = `${message} - ${response?.data?.discount} off`;
                                  promoCodeRef.current.innerHTML = message;
                                }
                              } catch (error) {
                                if (
                                  error?.response?.data &&
                                  error?.response?.data?.status &&
                                  error?.response?.data?.status === "failed"
                                ) {
                                  setIsPromoCodeValid(false);
                                  if (promoCodeRef?.current)
                                    promoCodeRef.current.innerHTML =
                                      `${error?.response?.data?.message}` ||
                                      "Invalid Promo code";
                                }
                              }
                            }}
                            value={promo_code}
                            placeholder="e.g 1C6TP838"
                          />
                          <p
                            ref={promoCodeRef}
                            className={`${
                              isPromoCodeValid
                                ? "text-green-500"
                                : "text-red-500"
                            } text-sm`}
                          ></p>
                          <div className="flex flex-col md:flex-row justify-between items-center md:pb-10 md:pt-3 space-y-3 md:space-y-0">
                            <button
                              className={`w-full md:w-fit px-20 rounded-full bg-tangerine text-white py-2 font-bold disabled:bg-tangerine/40 ${
                                (moq?.status === 0 ||
                                  moq?.moq_order_status !== "not placed") &&
                                "opacity-0"
                              }`}
                              type="button"
                              disabled={
                                moq?.status === 0 ||
                                moq?.moq_order_status !== "not placed"
                              }
                              onClick={() => {
                                if (
                                  moq?.status === 0 ||
                                  moq?.moq_order_status !== "not placed"
                                )
                                  return;

                                if (
                                  (bidAttributes?.length === 1 &&
                                    bidAttributes[0]?.color_id === 0 &&
                                    bidAttributes[0]?.size_id === 0) ||
                                  bidAttributes[0]?.quantity === 0
                                ) {
                                  return toast.error(
                                    "Please fill all the attributes to proceed to summary section !"
                                  );
                                }

                                const attributes_total_quantity =
                                  bidAttributes.reduce((accumulator, c) => {
                                    return accumulator + c.quantity;
                                  }, 0);

                                if (
                                  attributes_total_quantity !==
                                  parseInt(quantity)
                                ) {
                                  return toast.error(
                                    "Sum of quantity on order attrbutes must be same as MOQ quantity provided!"
                                  );
                                }
                                if (
                                  !below_moq_price > 0 &&
                                  quantity < moq?.min_quantity
                                ) {
                                  return toast.error(
                                    `Minimum quantity required for this order to be submitted is ${moq?.min_quantity} and not ${quantity}`
                                  );
                                }
                                setSection(1);
                              }}
                            >
                              View Summary
                            </button>

                            {moq?.status === 0 ||
                            moq?.moq_order_status !== "not placed" ? (
                              <button
                                className="w-full md:w-fit px-20 rounded-full bg-tangerine text-white py-2 font-bold disabled:bg-tangerine/40"
                                type="button"
                                onClick={() => {
                                  if (!authenticated) {
                                    openAuthModal();
                                  } else {
                                    setShowRequestModal(true);
                                  }
                                }}
                              >
                                Request to re-run MOQ
                              </button>
                            ) : (
                              <div className="w-full md:w-fit">
                                {authenticated ? (
                                  <button
                                    className="w-full md:w-fit px-20 rounded-full bg-tangerine text-white py-2 font-bold disabled:bg-tangerine/40 make-order-1"
                                    type="submit"
                                    disabled={
                                      moq?.status === 0 ||
                                      moq?.moq_order_status !== "not placed"
                                    }
                                  >
                                    {!loading
                                      ? "Make Order"
                                      : "Please wait ..."}
                                  </button>
                                ) : (
                                  <button
                                    className="w-full md:w-fit px-20 rounded-full bg-tangerine text-white py-2 font-bold disabled:bg-tangerine/40"
                                    type="button"
                                    disabled={
                                      moq?.status === 0 ||
                                      moq?.moq_order_status !== "not placed"
                                    }
                                    onClick={() => {
                                      if (
                                        moq?.status === 0 ||
                                        moq?.moq_order_status !== "not placed"
                                      )
                                        return;

                                      addOrderSummaryToCart();
                                    }}
                                  >
                                    Add to Cart
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    )}

                    {section === 1 && (
                      <div className="overflow-x-auto w-full space-y-5">
                        <h2 className="text-[#A68F69] font-extrabold text-lg">
                          Summary
                        </h2>

                        <div className="inline-block rounded-lg w-full">
                          <table className="w-full table-auto">
                            <thead className="text-base bg-[#986510] text-white">
                              <tr className="font-light text-left px-4 rounded-md">
                                {!(
                                  product?.readable_sizes &&
                                  product?.readable_sizes.length === 1 &&
                                  (product?.readable_sizes[0]?.label ===
                                    "None" ||
                                    product?.readable_sizes[0]?.label ===
                                      "none")
                                ) && (
                                  <td className="py-3 border-b font-bold px-4">
                                    Size
                                  </td>
                                )}

                                {!(
                                  product?.readable_colors &&
                                  product?.readable_colors.length === 1 &&
                                  (product?.readable_colors[0]?.label ===
                                    "None" ||
                                    product?.readable_colors[0]?.label ===
                                      "none")
                                ) && (
                                  <td className="py-3 border-b font-bold px-4">
                                    Color
                                  </td>
                                )}

                                <td className="py-3 border-b font-bold px-4">
                                  @Cost
                                </td>

                                <td className="py-3 border-b font-bold px-4">
                                  Quantity
                                </td>

                                <td className="py-3 border-b font-bold px-4">
                                  Total Cost
                                </td>

                                <td className="py-3 border-b font-bold px-4"></td>
                              </tr>
                            </thead>

                            <tbody className="text-sm font-normal text-gray-700 bg-[#f9f9f9] space-y-1">
                              {bidAttributes.map((attr, i) => (
                                <tr
                                  key={i}
                                  className="bg-white mb-[0.5px] border-b-4 rounded border-[#f9f9f9]"
                                >
                                  {!(
                                    product?.readable_sizes &&
                                    product?.readable_sizes.length === 1 &&
                                    (product?.readable_sizes[0]?.label ===
                                      "None" ||
                                      product?.readable_sizes[0]?.label ===
                                        "none")
                                  ) && (
                                    <td className="py-5 font-bold text-[#5B5B5B] px-4 capitalize">
                                      {
                                        product?.readable_sizes?.find(
                                          (size) =>
                                            size?.id === parseInt(attr?.size_id)
                                        )?.label
                                      }
                                    </td>
                                  )}

                                  {!(
                                    product?.readable_colors &&
                                    product?.readable_colors.length === 1 &&
                                    (product?.readable_colors[0]?.label ===
                                      "None" ||
                                      product?.readable_colors[0]?.label ===
                                        "none")
                                  ) && (
                                    <td className="py-5 font-bold text-[#5B5B5B] px-4 capitalize">
                                      {
                                        product?.readable_colors?.find(
                                          (color) =>
                                            color?.id ===
                                            parseInt(attr?.color_id)
                                        )?.label
                                      }
                                    </td>
                                  )}

                                  {product !== null &&
                                  moq?.product &&
                                  moq?.product[0] &&
                                  moq?.product[0]?.varying_sizes === 1 ? (
                                    <td className="py-5 font-bold text-[#5B5B5B] px-4">
                                      KES{" "}
                                      {moq?.moq_attributes?.find(
                                        (a) => a?.size_id === attr?.size_id
                                      )?.below_moq_price > 0 &&
                                      moq?.min_quantity > quantity
                                        ? moq?.moq_attributes?.find(
                                            (a) => a?.size_id === attr?.size_id
                                          )?.below_moq_price
                                        : moq?.moq_attributes?.find(
                                            (a) => a?.size_id === attr?.size_id
                                          )?.price || 0}
                                    </td>
                                  ) : (
                                    <td className="py-5 font-bold text-[#5B5B5B] px-4">
                                      KES{" "}
                                      {moq?.below_moq_price > 0 &&
                                      moq?.min_quantity > quantity
                                        ? moq?.below_moq_price?.toLocaleString()
                                        : moq?.price?.toLocaleString() || 0}
                                    </td>
                                  )}

                                  <td className="py-5 font-bold text-[#5B5B5B] px-4">
                                    {attr?.quantity}
                                  </td>

                                  {product !== null &&
                                  moq?.product &&
                                  moq?.product[0] &&
                                  moq?.product[0]?.varying_sizes === 1 ? (
                                    <td className="py-5 font-bold text-[#5B5B5B] px-4">
                                      KES{" "}
                                      {moq?.moq_attributes?.find(
                                        (a) => a?.size_id === attr?.size_id
                                      )?.below_moq_price > 0 &&
                                      moq?.min_quantity > quantity
                                        ? (
                                            attr?.quantity *
                                            moq?.moq_attributes?.find(
                                              (a) =>
                                                a?.size_id === attr?.size_id
                                            )?.below_moq_price
                                          ).toLocaleString() || 0
                                        : (
                                            attr?.quantity *
                                            moq?.moq_attributes?.find(
                                              (a) =>
                                                a?.size_id === attr?.size_id
                                            )?.price
                                          ).toLocaleString() || 0}
                                    </td>
                                  ) : (
                                    <td className="py-5 font-bold text-[#5B5B5B] px-4">
                                      KES{" "}
                                      {moq?.below_moq_price > 0 &&
                                      moq?.min_quantity > quantity &&
                                      !isNaN(
                                        attr?.quantity * moq?.below_moq_price
                                      ) &&
                                      !isNaN(attr?.quantity * moq?.price)
                                        ? (
                                            attr?.quantity *
                                            moq?.below_moq_price
                                          ).toLocaleString() || 0
                                        : (
                                            attr?.quantity * moq?.price
                                          ).toLocaleString() || 0}
                                    </td>
                                  )}

                                  <td className="py-5 font-bold text-[#5B5B5B] px-4">
                                    <div className="flex flex-row items-center space-x-1">
                                      <button
                                        type="button"
                                        className="p-[5px] bg-[#FAA51A] rounded-md text-white h-fit"
                                        onClick={() => setSection(0)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-4 h-4"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                          />
                                        </svg>
                                      </button>

                                      {bidAttributes.length !== 1 && (
                                        <button
                                          type="button"
                                          className="p-[5px] bg-[#FAA51A] rounded-md text-white h-fit"
                                          onClick={() => handleRemoveClick(i)}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-4 h-4"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                            />
                                          </svg>
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}

                              <tr className="bg-white">
                                {!(
                                  product?.readable_sizes &&
                                  product?.readable_sizes.length === 1 &&
                                  (product?.readable_sizes[0]?.label ===
                                    "None" ||
                                    product?.readable_sizes[0]?.label ===
                                      "none")
                                ) && (
                                  <td className="py-5 font-bold text-[#5B5B5B] px-4 capitalize">
                                    Total Cost
                                  </td>
                                )}

                                {!(
                                  product?.readable_colors &&
                                  product?.readable_colors.length === 1 &&
                                  (product?.readable_colors[0]?.label ===
                                    "None" ||
                                    product?.readable_colors[0]?.label ===
                                      "none")
                                ) &&
                                  product?.readable_sizes &&
                                  product?.readable_sizes.length === 1 &&
                                  (product?.readable_sizes[0]?.label ===
                                    "None" ||
                                    product?.readable_sizes[0]?.label ===
                                      "none") && (
                                    <td className="py-5 font-bold text-[#5B5B5B] px-4 capitalize">
                                      Total Cost
                                    </td>
                                  )}

                                {!(
                                  product?.readable_sizes &&
                                  product?.readable_sizes.length === 1 &&
                                  (product?.readable_sizes[0]?.label ===
                                    "None" ||
                                    product?.readable_sizes[0]?.label ===
                                      "none")
                                ) &&
                                  !(
                                    product?.readable_colors &&
                                    product?.readable_colors.length === 1 &&
                                    (product?.readable_colors[0]?.label ===
                                      "None" ||
                                      product?.readable_colors[0]?.label ===
                                        "none")
                                  ) && (
                                    <td className="py-5 font-bold text-[#5B5B5B] px-4 capitalize"></td>
                                  )}

                                <td className="py-5 font-bold text-[#5B5B5B] px-4">
                                  {product?.readable_sizes &&
                                  product?.readable_sizes.length === 1 &&
                                  (product?.readable_sizes[0]?.label ===
                                    "None" ||
                                    product?.readable_sizes[0]?.label ===
                                      "none") &&
                                  product?.readable_colors &&
                                  product?.readable_colors.length === 1 &&
                                  (product?.readable_colors[0]?.label ===
                                    "None" ||
                                    product?.readable_colors[0]?.label ===
                                      "none")
                                    ? "Total Cost"
                                    : ""}
                                </td>

                                <td className="py-5 font-bold text-[#5B5B5B] px-4">
                                  {bidAttributes
                                    .map((item) => item?.quantity)
                                    .reduce((prev, next) => prev + next)}
                                </td>

                                {product !== null &&
                                moq?.product &&
                                moq?.product[0] &&
                                moq?.product[0]?.varying_sizes === 1 ? (
                                  <td className="py-5 font-bold text-[#5B5B5B] px-4">
                                    KES {getTotal().toLocaleString() || 0}
                                  </td>
                                ) : (
                                  <td className="py-5 font-bold text-[#5B5B5B] px-4">
                                    KES{" "}
                                    {moq?.below_moq_price > 0 &&
                                    moq?.min_quantity > quantity
                                      ? (
                                          bidAttributes
                                            .map((item) => item?.quantity)
                                            .reduce(
                                              (prev, next) => prev + next
                                            ) * moq?.below_moq_price
                                        ).toLocaleString() || 0
                                      : (
                                          bidAttributes
                                            .map((item) => item?.quantity)
                                            .reduce(
                                              (prev, next) => prev + next
                                            ) * moq?.price
                                        ).toLocaleString() || 0}
                                  </td>
                                )}

                                <td className="py-5 font-bold text-[#5B5B5B] px-4"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div
                          className={`flex flex-col md:flex-row items-center ${
                            authenticated
                              ? "justify-between"
                              : "justify-between"
                          } mb-2 md:pb-10 space-y-4 md:space-y-0`}
                        >
                          <button
                            className="w-full md:w-fit px-20 rounded-full bg-tangerine text-white py-2 font-bold"
                            onClick={() => setSection(0)}
                          >
                            Back to Orders
                          </button>

                          <button
                            className="w-full md:w-fit px-20 rounded-full bg-tangerine text-white py-2 font-bold"
                            type="button"
                            onClick={() => addOrderSummaryToCart()}
                          >
                            Add to Cart
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <h1 className="text-2xl text-gray-700 dark:text-white font-bold mb-3">
                    {product !== null &&
                      moq !== null &&
                      moq?.product &&
                      toTitleCase(moq?.product[0]?.name)}
                  </h1>

                  {/* <p className='text-gray-600 dark:text-[#ECEBEB]'>{product !== null && moq !== null && moq?.product && toTitleCase(moq?.product[0]?.description)}</p> */}

                  <div
                    className="text-gray-600 dark:text-[#ECEBEB] dangerouslySetInnerHTML"
                    style={{ marginTop: "10px" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        product !== null && moq !== null && moq?.product
                          ? moq?.product[0]?.description
                          : "",
                    }}
                  />
                </Tab.Panel>

                <Tab.Panel>
                  <div className="flex flex-col relative">
                    {
                      <div className="absolute w-fit top-0 right-5">
                        <button
                          className="bg-tangerine text-white text-base rounded px-6 py-2"
                          onClick={() => {
                            if (!authenticated) {
                              openAuthModal();
                            } else {
                              toggleRatingModal();
                            }
                          }}
                        >
                          Add Review
                        </button>
                      </div>
                    }

                    {reviews.map((review, i) => (
                      <div
                        key={i}
                        className="w-full flex flex-row justify-start items-center gap-x-5 mt-4"
                      >
                        <div className="flex flex-col items-center justify-center">
                          <img
                            className="m-0 p-0"
                            src="/img/user.png"
                            alt="user"
                          />

                          <div className="flex flex-row justify-center items-center gap-x-1">
                            <ReactStars
                              value={review?.rating}
                              count={1}
                              size={26}
                              activeColor="#ffd700"
                              edit={false}
                            />

                            <h3 className="font-extrabold text-tangerine">
                              {review?.rating}
                            </h3>
                          </div>
                        </div>

                        <div className="flex flex-col col-span-4">
                          <h1 className="text-md font-semibold capitalize dark:text-white">
                            {review?.customer}
                          </h1>

                          <p className="capitalize text-sm text-gray-600 dark:text-[#eee]">
                            {review?.review}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>

      <RequestReRunMOQModal
        isOpen={showRequestModal}
        setIsOpen={setShowRequestModal}
        product_name={moq?.product[0]?.name || ""}
        product_link={window.location.href}
      />

      <Rating toggle={toggleRatingModal} open={show} product={product} />

      <OrderModal
        closeModal={closeModal}
        isOpen={isOpen}
        bidAttributes={bidAttributes}
        handleAddClick={handleAddClick}
        handleRemoveClick={handleRemoveClick}
        handleInputChange={handleInputChange}
        product={product}
        moq={moq}
        setQuantity={setQuantity}
        quantity={quantity}
        shipping={shipping}
        setShipping={setShipping}
        createBid={createBid}
        loading={loading}
        authenticated={authenticated}
        addBidToCart={addBidToCart}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reviews: state.reviews,

    user: state.user,

    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getReviewsById, createBid, showUser, getProductById, clearReviews },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Product));

import Cookies from "js-cookie";

const truncateText = (str, length) => {
  if (length == null) {
    length = 100;
  }

  const ending = "...";

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

const toTitleCase = (toTransform) => {
  toTransform = toTransform.toLowerCase();
  return toTransform.replace(/\b([a-z])/g, function (_, initial) {
    return initial.toUpperCase();
  });
};

const registerGtagEvent = (eventName) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
  });
  // Additional logic for your button click
  return eventName;
};

function findMinMax(arr) {
  let min = 0;

  let max = 0;

  if (arr === undefined) {
    return { min, max };
  } else {
    min = arr && arr?.length > 0 ? arr[0].price : 0;

    max = arr && arr?.length > 0 ? arr[0].price : 0;
  }

  if (arr && arr?.length > 0) {
    for (let i = 1, len = arr.length; i < len; i++) {
      let v = arr[i].price;
      min = v < min ? v : min;
      max = v > max ? v : max;
    }
  }

  return { min, max };
}

function tokenExpired() {
  const exists = fetchTokenExpiry();
  if (!exists) return false;
  const expiry = Cookies.get("ltdn_exp") || null;
  const currentTime = Date.now() / 1000;
  return currentTime > expiry;
}

function fetchTokenExpiry() {
  const token = Cookies.get("ltdn_exp") || null;
  return token;
}

function isAuthenticated() {
  const expiry = fetchTokenExpiry();
  if (!expiry) return false;
  const currentTime = Date.now() / 1000;
  const authenticated = expiry > currentTime;
  return authenticated;
}

function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

export {
  truncateText,
  toTitleCase,
  findMinMax,
  tokenExpired,
  removeTags,
  registerGtagEvent,
  isAuthenticated,
};
